import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_CATEGORIES,
  FILTER_TYPE_DATES,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_TYPES,
} from './DataFilterTypes';

class StoryDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_TYPES]: [],
    [FILTER_TYPE_CATEGORIES]: [],
    [FILTER_TYPE_DATES]: [],
  };

  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_TYPES, FILTER_TYPE_CATEGORIES, FILTER_TYPE_DATES],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.types
   * @param {FilterGroupItem[]|null} groupsWithItems.categories
   * @param {FilterGroupItem[]|null} groupsWithItems.dates
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.types,
        itemsType: FILTER_TYPE_TYPES,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.categories,
        itemsType: FILTER_TYPE_CATEGORIES,
      }),
      this.addDateGroupItems({
        group: groupsWithItems.dates,
        itemsType: FILTER_TYPE_DATES,
        title: 'Dates',
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        storyType: transformed[FILTER_TYPE_TYPES],
        category: transformed[FILTER_TYPE_CATEGORIES],
        published_date: transformed[FILTER_TYPE_DATES],
      },
    };
  }
}

export default StoryDataFilterHelper.create();
