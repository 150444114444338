<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Stories</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingStories && !stories.length" name="Stories" />

    <div class="content-wrp">
      <div class="row-considering-mobile">
        <EntityCard
          v-for="story in stories"
          :key="story.id"
          class="col-12 col-md-6 col-lg-4"
          :data="story"
          data-aos="fade-up"
          type="stories"
        />
      </div>
      <infinite-loading :identifier="infiniteLoaderId" @infinite="loadMoreStories" />
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import StoryDataFilterHelper from '@/helpers/data-filter/StoryDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import storiesPageQuery from '@/graphql/story/Stories.query.gql';
import storiesPageFilterQuery from '@/graphql/story/StoriesPageFilter.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import EntityCard from '@/components/partials/EntityCard';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 9;

export default {
  name: 'StoriesPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    EntityCard,
  },
  metaInfo: {
    title: 'Stories',
  },
  data() {
    return {
      description: '',
      stories: [],
      loadingStories: true,
      getMoreStories: false,
      page: null,
      infiniteLoaderId: +new Date(),
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return StoryDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
  },
  created() {
    this.filterValues = StoryDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = StoryDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.story,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  methods: {
    getFilterData() {
      return this.$apollo
        .query({
          query: storiesPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = StoryDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = StoryDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMoreStories(loadingState) {
      this.loadingStories = true;
      this.$apollo
        .query({
          query: storiesPageQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            nextCursor: this.page,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          const { storiesPage } = data;

          this.stories.push(...storiesPage.data);
          this.getMoreStories = storiesPage.hasMorePages;
          this.loadingStories = false;

          if (!this.getMoreStories) {
            loadingState.complete();
            return;
          }
          this.page = storiesPage.nextCursor;
          loadingState.loaded();
        })
        .catch(() => {
          this.loadingStories = false;
          loadingState.complete();
        });
    },
    resetFetchDataOptions() {
      this.loadingStories = true;
      this.page = null;
      this.stories = [];
      this.infiniteLoaderId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;
}
.page-desc {
  max-width: 550px;
}
.content-wrp {
  min-height: 300px;
}
</style>
